/** @jsx jsx */
import { Box, Grid, jsx, Flex, Input, Label, Spinner } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useRef, useState, useEffect } from "react"
import {
  useDispatchCurrentUser,
  useCurrentUser,
} from "../components/CurrentUser"
import LoginLayout from "../components/loginLayout"
import LoginLogo from "../components/LoginLogo"
import { callApi, callApiNoAuth } from "../utils"
import { navigate, Link } from "gatsby"
const API_URL = process.env.GATSBY_API_URL || "http://localhost:1337"

const ForgotPasswordPage = ({ location }) => {
  const emailRef = useRef()
  const [errorMsg, setErrorMsg] = useState(null)
  //this is to prevent double submits
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatchCurrentUser()

  let currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      navigate("/app")
    }
    // console.log('currentUser',currentUser)
  }, [currentUser])

  const handleSubmit = async e => {
    e.preventDefault()
    setErrorMsg(null)
    setSubmitted(true)
    try {
      const response = await callApiNoAuth("/auth/forgot-password", "POST", {
        email: emailRef.current.value,
        url: `${API_URL}/reset-password`,
      })

      if (response.ok) {
        setErrorMsg("We have sent you an email with a password reset link.")
        setSubmitted(false)
      } else {
        const message = `An error has occured:${response.message.messages}`
        setErrorMsg(message)
        //throw new Error(message)
        setSubmitted(false)
      }
    } catch (err) {
      setErrorMsg(err)
      console.log(err)
      setSubmitted(false)
    }
  }

  return (
    <div sx={styles}>
      <LoginLayout>
        <LoginLogo />

        <Box className="forgottonPassword">
          <Box as="form" onSubmit={handleSubmit}>
            <h1>Forgot Password?</h1>
            <fieldset id="password-reset" sx={{ border: "none", py: 3 }}>
              <p>
                Please enter your email address to receive a password reset
                email.
              </p>
              <div>
                <Label htmlFor="email-address">Email</Label>
                <Input
                  ref={emailRef}
                  type="email"
                  name="email-address"
                  id="email-address"
                />
              </div>
            </fieldset>
            <div className="buttonRow">
              {submitted && <Spinner />}
              <Input type="submit" value="Submit" disabled={submitted} />
            </div>
            <div>{errorMsg && <p className="error">{errorMsg}</p>}</div>
            <div className="otherLinks">
              <Link to="/sign-up">Sign up</Link>
              <Link to="/login">Back To Login</Link>
            </div>
          </Box>
        </Box>
      </LoginLayout>
    </div>
  )
}

export default ForgotPasswordPage

const styles = {
  "& .forgottonPassword": {
    margin: "0 auto",
    maxWidth: "50%",
    background: "white",
    padding: 4,
    "& p.error": {
      color: "red",
    },
    "input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover:enabled": {
        background: darken("accent", 0.1),
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3,
      },
    },
    "input[type=text]:focus-visible": {
      outline: "red",
    },
    a: {
      color: "accent",
    },
    ".otherLinks": {
      margin: "0 auto",
      mt: 3,
      textAlign: "center",
      a: {
        pr: 3,
      },
    },
  },
  "& .error": {
    color: "red",
  },
  "& .buttonRow svg": {
    color: "accent",
    textAlign: "center",
    mx: "auto",
    display: "block",
    mb: 2,
  },
}
